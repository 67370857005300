// File: /Users/amadkata/linkportal/src/components/PublicProfile.tsx

import {
  collection,
  doc,
  getDoc,
  onSnapshot,
  query,
  where,
} from 'firebase/firestore';
import React, { useCallback, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import {
  FaFacebook,
  FaInstagram,
  FaLinkedin,
  FaSnapchatGhost,
  FaTelegramPlane,
  FaTiktok,
  FaTwitter,
  FaWhatsapp,
  FaYoutube,
} from 'react-icons/fa';
import { TbBrandThreads } from 'react-icons/tb';

import '../styles/PublicProfile.css';
import { db } from '../utils/firebaseConfig';

interface Link {
  id: string;
  name: string;
  url: string;
  order: number;
  active: boolean;
  icon?: string;
}

interface Header {
  id: string;
  text: string;
  order: number;
}

interface UserData {
  username: string;
  profilePicture?: string;
  bio?: string;
  backgroundColor?: string;
  socialLinks?: {
    instagram?: string;
    twitter?: string;
    facebook?: string;
    linkedin?: string;
    /* ...لو تريد منصات أخرى أضفها هنا */
  };
}

/** 
 * دالة لاستخراج أيقونة المنصة بناءً على نص الاسم/الرابط.
 * إن لم نكتشف منصة معروفة، نعيد null.
 */
function getSocialIcon(text: string) {
  const lower = text.toLowerCase();

  if (lower.includes('instagram') || lower.includes('انستا')) {
    return <FaInstagram />;
  } else if (lower.includes('facebook') || lower.includes('فيس')) {
    return <FaFacebook />;
  } else if (lower.includes('linkedin') || lower.includes('لينكد')) {
    return <FaLinkedin />;
  } else if (lower.includes('twitter') || lower.includes('تويتر')) {
    return <FaTwitter />;
  } else if (lower.includes('youtube') || lower.includes('يوتيوب')) {
    return <FaYoutube />;
  } else if (lower.includes('tiktok') || lower.includes('تيك')) {
    return <FaTiktok />;
  } else if (lower.includes('threads') || lower.includes('ثريد')) {
    return <TbBrandThreads />;
  } else if (lower.includes('snap') || lower.includes('سناب')) {
    return <FaSnapchatGhost />;
  } else if (lower.includes('telegram') || lower.includes('تلجرام')) {
    return <FaTelegramPlane />;
  } else if (lower.includes('whatsapp') || lower.includes('واتساب')) {
    return <FaWhatsapp />;
  }

  return null;
}

const PublicProfile: React.FC = () => {
  const { username } = useParams<{ username: string }>();

  // userData: undefined = لم نحدد بعد إذا كان المستخدم موجود أم لا
  // userData: null = المستخدم غير موجود
  // userData: كائن = بيانات المستخدم موجودة
  const [userData, setUserData] = useState<UserData | null | undefined>(
    undefined
  );

  // الروابط + العناوين
  const [linksAndHeaders, setLinksAndHeaders] = useState<(Link | Header)[]>([]);

  // دالة مساعدة لتجميع الروابط والعناوين في مصفوفة واحدة مرتبة
  const updateCombinedData = useCallback(
    (newLinks: Link[] | null, newHeaders: Header[] | null) => {
      setLinksAndHeaders((prev) => {
        const currentLinks = newLinks || (prev.filter((item) => 'url' in item) as Link[]);
        const currentHeaders = newHeaders || (prev.filter((item) => 'text' in item) as Header[]);
        return [...currentLinks, ...currentHeaders].sort((a, b) => a.order - b.order);
      });
    },
    []
  );

  useEffect(() => {
    let unsubscribeUser: () => void = () => {};
    let unsubscribeLinks: () => void = () => {};
    let unsubscribeHeaders: () => void = () => {};

    const fetchUserData = async () => {
      try {
        if (!username) {
          console.error('Username is undefined.');
          setUserData(null);
          return;
        }

        // البحث عن معرف المستخدم (userId) عبر usernames
        const usernameDoc = await getDoc(doc(db, 'usernames', username));
        if (!usernameDoc.exists()) {
          console.error('User not found in Firestore.');
          setUserData(null);
          return;
        }
        const userId = usernameDoc.data()?.uid;

        // متابعة مستند المستخدم
        unsubscribeUser = onSnapshot(
          doc(db, 'users', userId),
          (docSnap) => {
            if (docSnap.exists()) {
              setUserData(docSnap.data() as UserData);
            } else {
              console.error('User data does not exist.');
              setUserData(null);
            }
          },
          (error) => {
            console.error('Error in user onSnapshot:', error);
            setUserData(null);
          }
        );

        // متابعة الروابط (links) المفعّلة
        const linksQuery = query(
          collection(db, 'links'),
          where('userId', '==', userId),
          where('active', '==', true)
        );
        unsubscribeLinks = onSnapshot(
          linksQuery,
          (snapshot) => {
            const fetchedLinks = snapshot.docs.map(
              (doc) => ({ id: doc.id, ...doc.data() } as Link)
            );
            updateCombinedData(fetchedLinks, null);
          },
          (error) => {
            console.error('Error in links onSnapshot:', error);
          }
        );

        // متابعة العناوين
        const headersQuery = query(
          collection(db, 'headers'),
          where('userId', '==', userId)
        );
        unsubscribeHeaders = onSnapshot(
          headersQuery,
          (snapshot) => {
            const fetchedHeaders = snapshot.docs.map(
              (doc) => ({ id: doc.id, ...doc.data() } as Header)
            );
            updateCombinedData(null, fetchedHeaders);
          },
          (error) => {
            console.error('Error in headers onSnapshot:', error);
          }
        );
      } catch (error) {
        console.error('Error fetching user data:', error);
        setUserData(null);
      }
    };

    fetchUserData();

    return () => {
      unsubscribeUser();
      unsubscribeLinks();
      unsubscribeHeaders();
    };
  }, [username, updateCombinedData]);

  // عند الضغط على الرابط
  const handleLinkClick = (url: string) => {
    let fullUrl = url.trim();
    if (!fullUrl) {
      console.error('URL is empty.');
      return;
    }
    if (!fullUrl.match(/^https?:\/\//i)) {
      fullUrl = 'https://' + fullUrl;
    }
    try {
      new URL(fullUrl);
      window.open(fullUrl, '_blank', 'noopener,noreferrer');
    } catch (error) {
      console.error('Invalid URL:', fullUrl, error);
    }
  };

  return (
    <div
      className="public-profile"
      style={{
        // خلفية بلون المستخدم أو شفافة إن لم يحدد
        backgroundColor: userData?.backgroundColor ?? 'transparent',
      }}
    >
      <div className="profile-container">
        {userData === undefined ? (
          // لم نحدّد بعد هل المستخدم موجود أم لا
          <></>
        ) : userData === null ? (
          // المستخدم غير موجود
          <div className="no-user-found">
            <h2>User not found</h2>
            <p>This profile may have been removed or never existed.</p>
          </div>
        ) : (
          // وجدنا بيانات
          <>
            <div className="profile-header">
              {userData.profilePicture ? (
                <img
                  src={userData.profilePicture}
                  alt="Profile"
                  className="profile-picture"
                />
              ) : (
                <div className="avatar">
                  {userData.username?.[0]?.toUpperCase() || 'U'}
                </div>
              )}

              <h1 className="username">@{userData.username || 'User'}</h1>
              {userData.bio && <p className="bio">{userData.bio}</p>}

              {/* أيقونات مواقع التواصل المخزنة في userData.socialLinks */}
              <div className="social-links">
                {userData.socialLinks?.instagram && (
                  <a
                    href={userData.socialLinks.instagram}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <FaInstagram />
                  </a>
                )}
                {userData.socialLinks?.twitter && (
                  <a
                    href={userData.socialLinks.twitter}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <FaTwitter />
                  </a>
                )}
                {userData.socialLinks?.facebook && (
                  <a
                    href={userData.socialLinks.facebook}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <FaFacebook />
                  </a>
                )}
                {userData.socialLinks?.linkedin && (
                  <a
                    href={userData.socialLinks.linkedin}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <FaLinkedin />
                  </a>
                )}
              </div>
            </div>

            {/* هنا نعرض الروابط والعناوين */}
            <div className="content">
              {linksAndHeaders.map((item) =>
                'name' in item ? (
                  <button
                    key={item.id}
                    onClick={() => handleLinkClick(item.url)}
                    className="link-item"
                  >
                    {/* محاولة تحديد الأيقونة إن وجد اسم المنصة */}
                    {(() => {
                      const icon = getSocialIcon(item.name + ' ' + item.url);
                      if (icon) {
                        return (
                          <span
                            style={{
                              marginRight: '8px',
                              fontSize: '1.2rem',
                              display: 'flex',
                              alignItems: 'center',
                            }}
                          >
                            {icon}
                          </span>
                        );
                      }
                      // لو عنده أيقونة مخصصة
                      if (item.icon) {
                        return (
                          <img
                            src={item.icon}
                            alt=""
                            className="link-icon"
                            style={{ marginRight: '8px' }}
                          />
                        );
                      }
                      return null;
                    })()}

                    {item.name}
                  </button>
                ) : (
                  <div key={item.id} className="header-text">
                    {item.text}
                  </div>
                )
              )}
            </div>
          </>
        )}
      </div>

      <div className="linkat-logo">Powered by linkat</div>
    </div>
  );
};

export default PublicProfile;
