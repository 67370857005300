// File: /Users/amadkata/linkportal/src/components/Dashboard.tsx

import React, {
  ChangeEvent,
  FocusEvent,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import { useNavigate } from 'react-router-dom';

import { getAuth, onAuthStateChanged, signOut, User } from 'firebase/auth';
import {
  addDoc,
  collection,
  deleteDoc,
  doc,
  onSnapshot,
  query,
  runTransaction,
  updateDoc,
  where,
  writeBatch,
} from 'firebase/firestore';

import {
  DragDropContext,
  Draggable,
  Droppable,
  DropResult,
} from '@hello-pangea/dnd';

import {
  FaCog,
  FaEye,
  FaGripVertical,
  FaLink,
  FaPlus,
  FaSignOutAlt,
  FaTrash,
  FaUser,
} from 'react-icons/fa';

// استيراد أيقونات المنصات:
import {
  FaFacebookF,
  FaInstagram,
  FaSnapchatGhost,
  FaTelegramPlane,
  FaTiktok,
  FaTwitter,
  FaWhatsapp,
  FaYoutube,
} from 'react-icons/fa';
import { TbBrandThreads } from 'react-icons/tb';

import '../styles/Dashboard.css';
import { db } from '../utils/firebaseConfig';

// =====================
// تعريف الأنواع (Interfaces)
// =====================
interface Link {
  id: string;
  name: string;
  url: string;
  description?: string;
  order: number;
  active: boolean;
  userId: string;
  createdAt: Date;
  clicks: number;
  icon?: string;
}

interface Header {
  id: string;
  text: string;
  order: number;
  userId: string;
}

interface UserData {
  uid: string;
  email: string;
  username: string;
  createdAt: Date;
  profilePicture?: string;
  bio?: string;
  backgroundColor: string;
}

/** لحفظ ما يحرره المستخدم مؤقتًا (قبل رفعه لـ Firestore) */
interface EditingInputs {
  [itemId: string]: {
    name?: string;
    url?: string;
    text?: string;
  };
}

// =================================================
// دالة مساعدِة لاكتشاف وتحديد الأيقونة حسب اسم المنصة
// =================================================
function getSocialIcon(text: string) {
  const lower = text.toLowerCase();

  if (lower.includes('instagram') || lower.includes('انستقرام')) {
    return <FaInstagram />;
  } else if (lower.includes('facebook') || lower.includes('فيس')) {
    return <FaFacebookF />;
  } else if (lower.includes('youtube') || lower.includes('يوتيوب')) {
    return <FaYoutube />;
  } else if (lower.includes('tiktok') || lower.includes('تيك')) {
    return <FaTiktok />;
  } else if (lower.includes('threads') || lower.includes('ثريد')) {
    return <TbBrandThreads />;
  } else if (lower.includes('snap') || lower.includes('سناب')) {
    return <FaSnapchatGhost />;
  } else if (lower.includes('telegram') || lower.includes('تلجرام')) {
    return <FaTelegramPlane />;
  } else if (lower.includes('whatsapp') || lower.includes('واتس')) {
    return <FaWhatsapp />;
  } else if (lower.includes('twitter') || lower.includes('تويتر')) {
    return <FaTwitter />;
  }

  // إذا لم نجد منصة معروفة، لا نعرض أي أيقونة
  return null;
}

const Dashboard: React.FC = () => {
  // حالات
  const [user, setUser] = useState<User | null>(null);
  const [userData, setUserData] = useState<UserData | null>(null);
  const [linksAndHeaders, setLinksAndHeaders] = useState<(Link | Header)[]>([]);
  const [editingInputs, setEditingInputs] = useState<EditingInputs>({});

  // واجهة المستخدم
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [copySuccess, setCopySuccess] = useState(false);
  const [newUsername, setNewUsername] = useState('');
  const [usernameError, setUsernameError] = useState<string | null>(null);
  const [isUpdatingUsername, setIsUpdatingUsername] = useState(false);

  // مراجع
  const unsubscribeUserDataRef = useRef<(() => void) | undefined>();
  const unsubscribeLinksRef = useRef<(() => void) | undefined>();
  const unsubscribeHeadersRef = useRef<(() => void) | undefined>();
  const updateTimersRef = useRef<{ [key: string]: NodeJS.Timeout }>({});

  const auth = getAuth();
  const navigate = useNavigate();

  // =====================
  // جلب بيانات المستخدم
  // =====================
  const fetchUserData = useCallback((userId: string) => {
    const userDocRef = doc(db, 'users', userId);
    const unsubscribeUserData = onSnapshot(userDocRef, (snap) => {
      if (snap.exists()) {
        const data = snap.data() as UserData;
        setUserData(data);
        setNewUsername(data.username || '');
      }
    });
    return unsubscribeUserData;
  }, []);

  // =====================
  // جلب الروابط والعناوين
  // =====================
  const fetchLinksAndHeaders = useCallback((userId: string) => {
    const linksQuery = query(collection(db, 'links'), where('userId', '==', userId));
    const headersQuery = query(collection(db, 'headers'), where('userId', '==', userId));

    const unsubscribeLinks = onSnapshot(linksQuery, (snapshot) => {
      const fetchedLinks = snapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      })) as Link[];
      setLinksAndHeaders((prev) => {
        const onlyHeaders = prev.filter((x) => !('name' in x));
        return [...fetchedLinks, ...onlyHeaders].sort((a, b) => a.order - b.order);
      });
    });

    const unsubscribeHeaders = onSnapshot(headersQuery, (snapshot) => {
      const fetchedHeaders = snapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      })) as Header[];
      setLinksAndHeaders((prev) => {
        const onlyLinks = prev.filter((x) => 'name' in x);
        return [...onlyLinks, ...fetchedHeaders].sort((a, b) => a.order - b.order);
      });
    });

    return { unsubscribeLinks, unsubscribeHeaders };
  }, []);

  // =====================
  // مراقبة المستخدم (Auth)
  // =====================
  useEffect(() => {
    const unsubscribeAuth = onAuthStateChanged(auth, (currentUser) => {
      if (currentUser) {
        setUser(currentUser);

        unsubscribeUserDataRef.current?.();
        unsubscribeLinksRef.current?.();
        unsubscribeHeadersRef.current?.();

        unsubscribeUserDataRef.current = fetchUserData(currentUser.uid);
        const { unsubscribeLinks, unsubscribeHeaders } = fetchLinksAndHeaders(currentUser.uid);
        unsubscribeLinksRef.current = unsubscribeLinks;
        unsubscribeHeadersRef.current = unsubscribeHeaders;
      } else {
        setUser(null);

        unsubscribeUserDataRef.current?.();
        unsubscribeLinksRef.current?.();
        unsubscribeHeadersRef.current?.();
        unsubscribeUserDataRef.current = undefined;
        unsubscribeLinksRef.current = undefined;
        unsubscribeHeadersRef.current = undefined;

        navigate('/login');
      }
    });

    return () => {
      unsubscribeAuth?.();
      unsubscribeUserDataRef.current?.();
      unsubscribeLinksRef.current?.();
      unsubscribeHeadersRef.current?.();
    };
  }, [auth, navigate, fetchUserData, fetchLinksAndHeaders]);

  // =====================
  // إضافة Link أو Header
  // =====================
  const addLink = async () => {
    if (!user) return;
    const newLink: Omit<Link, 'id'> = {
      name: 'New Link',
      url: '',
      description: '',
      order: linksAndHeaders.length,
      active: true,
      userId: user.uid,
      createdAt: new Date(),
      clicks: 0,
      icon: '',
    };
    try {
      await addDoc(collection(db, 'links'), newLink);
    } catch (error) {
      console.error('Error adding link:', error);
    }
  };

  const addHeader = async () => {
    if (!user) return;
    const newHeader: Omit<Header, 'id'> = {
      text: 'New Header',
      order: linksAndHeaders.length,
      userId: user.uid,
    };
    try {
      await addDoc(collection(db, 'headers'), newHeader);
    } catch (error) {
      console.error('Error adding header:', error);
    }
  };

  // =====================
  // حذف عنصر
  // =====================
  const deleteItem = async (itemId: string, itemType: 'link' | 'header') => {
    const collectionName = itemType === 'link' ? 'links' : 'headers';
    try {
      await deleteDoc(doc(db, collectionName, itemId));
    } catch (error) {
      console.error('Error deleting item:', error);
    }
  };

  // =====================
  // السحب والإفلات لإعادة الترتيب
  // =====================
  const handleDragEnd = async (result: DropResult) => {
    if (!result.destination) return;

    const reordered = Array.from(linksAndHeaders);
    const [removed] = reordered.splice(result.source.index, 1);
    reordered.splice(result.destination.index, 0, removed);

    const updatedItems = reordered.map((item, index) => ({
      ...item,
      order: index,
    }));
    setLinksAndHeaders(updatedItems);

    try {
      const batch = writeBatch(db);
      for (const item of updatedItems) {
        const collectionName = 'name' in item ? 'links' : 'headers';
        batch.update(doc(db, collectionName, item.id), { order: item.order });
      }
      await batch.commit();
    } catch (error) {
      console.error('Error updating order:', error);
    }
  };

  // =====================
  // تحرير العناصر (editingInputs)
  // =====================
  const handleInputChange = (
    e: ChangeEvent<HTMLInputElement>,
    itemId: string,
    field: 'name' | 'url' | 'text'
  ) => {
    const value = e.target.value;
    setEditingInputs((prev) => ({
      ...prev,
      [itemId]: { ...prev[itemId], [field]: value },
    }));
  };

  const commitInputChange = useCallback(
    async (itemId: string) => {
      const item = linksAndHeaders.find((x) => x.id === itemId);
      if (!item) return;

      const editedData = editingInputs[itemId];
      if (!editedData) return;

      const collectionName = 'name' in item ? 'links' : 'headers';
      const finalData = { ...item, ...editedData };

      // إزالة الحقول غير اللازمة
      if (!('name' in item)) {
        delete (finalData as any).name;
        delete (finalData as any).url;
      } else {
        delete (finalData as any).text;
      }
      delete (finalData as any).id;
      delete (finalData as any).userId;
      delete (finalData as any).createdAt;
      delete (finalData as any).clicks;
      delete (finalData as any).order;

      try {
        await updateDoc(doc(db, collectionName, itemId), finalData);
      } catch (error) {
        console.error(`Error updating ${collectionName}:`, error);
      }
    },
    [editingInputs, linksAndHeaders]
  );

  // تأخير الحفظ (Debounce)
  const handleDebouncedCommit = (itemId: string) => {
    if (updateTimersRef.current[itemId]) {
      clearTimeout(updateTimersRef.current[itemId]);
    }
    updateTimersRef.current[itemId] = setTimeout(() => {
      commitInputChange(itemId);
      delete updateTimersRef.current[itemId];
    }, 500);
  };

  const handleBlur = (e: FocusEvent<HTMLInputElement>, itemId: string) => {
    commitInputChange(itemId);
  };

  // تفعيل / إلغاء تفعيل الرابط
  const toggleLinkActive = async (itemId: string, active: boolean) => {
    const item = linksAndHeaders.find((x) => x.id === itemId);
    if (!item || !('name' in item)) return;
    try {
      await updateDoc(doc(db, 'links', itemId), { active });
    } catch (error) {
      console.error('Error toggling link active:', error);
    }
  };

  // =====================
  // تغيير لون الخلفية
  // =====================
  const handleBackgroundColorChange = async (color: string) => {
    setUserData((prev) => (prev ? { ...prev, backgroundColor: color } : prev));
    if (user && userData) {
      const timerKey = 'backgroundColor';
      if (updateTimersRef.current[timerKey]) {
        clearTimeout(updateTimersRef.current[timerKey]);
      }
      updateTimersRef.current[timerKey] = setTimeout(async () => {
        try {
          await updateDoc(doc(db, 'users', user.uid), { backgroundColor: color });
        } catch (error) {
          console.error('Error updating background color:', error);
        }
        delete updateTimersRef.current[timerKey];
      }, 500);
    }
  };

  // =====================
  // نسخ الرابط العام
  // =====================
  const copylinkatURL = () => {
    const url = `${window.location.origin}/${userData?.username}`;
    navigator.clipboard
      .writeText(url)
      .then(() => {
        setCopySuccess(true);
        setTimeout(() => setCopySuccess(false), 3000);
      })
      .catch((err) => {
        console.error('Failed to copy:', err);
      });
  };

  // =====================
  // تحديث اسم المستخدم
  // =====================
  const updateUsername = async () => {
    if (user && userData) {
      setIsUpdatingUsername(true);
      setUsernameError(null);

      try {
        await runTransaction(db, async (transaction) => {
          const newUsernameRef = doc(db, 'usernames', newUsername);
          const newUsernameDoc = await transaction.get(newUsernameRef);

          if (newUsernameDoc.exists() && newUsernameDoc.data()?.uid !== user.uid) {
            throw new Error('This username is already taken. Please choose another one.');
          }

          const userDocRef = doc(db, 'users', user.uid);
          const userDoc = await transaction.get(userDocRef);
          if (!userDoc.exists()) {
            throw new Error('User does not exist.');
          }

          const currentUsername = userData.username;
          if (currentUsername === newUsername) {
            throw new Error('The new username is the same as the current one.');
          }

          transaction.update(userDocRef, { username: newUsername });

          if (currentUsername) {
            transaction.delete(doc(db, 'usernames', currentUsername));
          }

          transaction.set(newUsernameRef, {
            uid: user.uid,
            email: user.email,
          });
        });

        setIsModalOpen(false);
        setNewUsername(userData.username);
        alert('Username updated successfully!');
      } catch (error: any) {
        setUsernameError(
          error.message || 'An error occurred while updating the username.'
        );
      } finally {
        setIsUpdatingUsername(false);
      }
    }
  };

  const cancelUsernameEdit = () => {
    setIsModalOpen(false);
    setNewUsername(userData?.username || '');
    setUsernameError(null);
  };

  // نظافة المؤقتات
  useEffect(() => {
    const timers = updateTimersRef.current;
    return () => {
      Object.values(timers).forEach(clearTimeout);
    };
  }, []);

  // تسجيل الخروج
  const handleLogout = () => {
    signOut(auth)
      .then(() => navigate('/login'))
      .catch((error) => console.error('Error signing out:', error));
  };

  // =====================
  // واجهة العرض
  // =====================
  return (
    <div className="dashboard-container">
      {/* الشريط الجانبي */}
      <aside className="sidebar">
        <div className="top-section">
          <div
            className="avatar-logo-section"
            onClick={() => setIsMenuOpen(!isMenuOpen)}
          >
            <div className="avatar-logo">
              {userData?.username?.[0]?.toUpperCase() || 'U'}
            </div>
            <div className="logo">linkat</div>
          </div>
        </div>

        <nav>
          <ul>
            <li className="active">
              <FaLink className="nav-icon" /> Links
            </li>
            <li>
              <FaEye className="nav-icon" /> Appearance
            </li>
            <li>
              <FaCog className="nav-icon" /> Settings
            </li>
          </ul>
        </nav>

        {isMenuOpen && (
          <div className="user-profile-menu">
            <div className="user-details">
              <div className="avatar-large">
                {userData?.username?.[0]?.toUpperCase() || 'U'}
              </div>
              <span className="full-username">@{userData?.username || 'User'}</span>
            </div>
            <button className="create-new-button">Create a new linkat</button>

            <div className="menu-section">
              <h3>Account</h3>
              <ul>
                <li onClick={() => setIsModalOpen(true)}>
                  <FaUser /> My account
                </li>
              </ul>
            </div>

            <div className="menu-section">
              <h3>Appearance</h3>
              <ul>
                <li onClick={copylinkatURL}>
                  <FaLink /> Your Profile Link
                </li>
              </ul>
            </div>

            <ul className="menu-actions">
              <li onClick={handleLogout}>
                <FaSignOutAlt /> Sign out
              </li>
            </ul>
          </div>
        )}
      </aside>

      {/* المحتوى الرئيسي */}
      <main className="main-content">
        <header>
          <div className="linkat-url">
            🔥 Your linkat is live:{' '}
            <a href={`/${userData?.username}`}>
              {window.location.origin}/{userData?.username}
            </a>
            <button className="copy-button" onClick={copylinkatURL}>
              Copy URL
            </button>
            {copySuccess && <span className="copy-success">Link copied!</span>}
          </div>
        </header>

        <section className="controls">
          <div className="buttons">
            <button onClick={addLink} className="control-button">
              <FaPlus /> Add Link
            </button>
            <button onClick={addHeader} className="control-button">
              <FaPlus /> Add Header
            </button>
          </div>
          <div className="color-picker">
            <label htmlFor="background-color">Background Color:</label>
            <input
              type="color"
              id="background-color"
              value={userData?.backgroundColor || '#ffffff'}
              onChange={(e) => handleBackgroundColorChange(e.target.value)}
            />
          </div>
        </section>

        {/* قائمة الروابط والعناوين */}
        <section className="links-list">
          {linksAndHeaders.length === 0 ? (
            <div className="empty-state">
              <div className="empty-icon">📎</div>
              <p>Show the world who you are.</p>
              <p>Add a link or header to get started.</p>
            </div>
          ) : (
            <DragDropContext onDragEnd={handleDragEnd}>
              <Droppable droppableId="linksAndHeaders">
                {(provided) => (
                  <div {...provided.droppableProps} ref={provided.innerRef}>
                    {linksAndHeaders.map((item, index) => {
                      const isLink = 'name' in item;
                      const itemId = item.id;
                      const editingValue = editingInputs[itemId] || {};

                      const inputName = isLink
                        ? editingValue.name ?? (item as Link).name
                        : undefined;
                      const inputUrl = isLink
                        ? editingValue.url ?? (item as Link).url
                        : undefined;
                      const inputText = !isLink
                        ? editingValue.text ?? (item as Header).text
                        : undefined;

                      return (
                        <Draggable
                          key={itemId}
                          draggableId={itemId}
                          index={index}
                        >
                          {(provided) => (
                            <div
                              className="item"
                              ref={provided.innerRef}
                              {...provided.draggableProps}
                            >
                              <div
                                className="item-drag-handle"
                                {...provided.dragHandleProps}
                              >
                                <FaGripVertical />
                              </div>

                              {isLink ? (
                                <div className="item-content">
                                  <input
                                    type="text"
                                    value={inputName}
                                    onChange={(e) => {
                                      handleInputChange(e, itemId, 'name');
                                      handleDebouncedCommit(itemId);
                                    }}
                                    onBlur={(e) => handleBlur(e, itemId)}
                                    className="item-input"
                                    placeholder="Enter link title"
                                  />
                                  <input
                                    type="url"
                                    value={inputUrl}
                                    onChange={(e) => {
                                      handleInputChange(e, itemId, 'url');
                                      handleDebouncedCommit(itemId);
                                    }}
                                    onBlur={(e) => handleBlur(e, itemId)}
                                    className="item-input"
                                    placeholder="Enter URL"
                                  />
                                </div>
                              ) : (
                                <div className="item-content">
                                  <input
                                    type="text"
                                    value={inputText}
                                    onChange={(e) => {
                                      handleInputChange(e, itemId, 'text');
                                      handleDebouncedCommit(itemId);
                                    }}
                                    onBlur={(e) => handleBlur(e, itemId)}
                                    className="item-input"
                                    placeholder="Enter header text"
                                  />
                                </div>
                              )}

                              <div className="item-actions">
                                {isLink && (
                                  <>
                                    <button
                                      className={`toggle-button ${
                                        (item as Link).active ? 'active' : ''
                                      }`}
                                      onClick={() =>
                                        toggleLinkActive(itemId, !(item as Link).active)
                                      }
                                    />
                                    <FaEye
                                      className="small-eye-icon"
                                      onClick={() =>
                                        window.open(
                                          (item as Link).url.startsWith('http')
                                            ? (item as Link).url
                                            : `https://${(item as Link).url}`,
                                          '_blank'
                                        )
                                      }
                                    />
                                  </>
                                )}
                                <button
                                  className="delete-button"
                                  onClick={() =>
                                    deleteItem(itemId, isLink ? 'link' : 'header')
                                  }
                                >
                                  <FaTrash />
                                </button>
                              </div>
                            </div>
                          )}
                        </Draggable>
                      );
                    })}
                    {provided.placeholder}
                  </div>
                )}
              </Droppable>
            </DragDropContext>
          )}
        </section>
      </main>

      {/* المعاينة الجانبية (Preview) */}
      <aside className="preview">
        <div className="phone-mockup">
          <div
            className="phone-screen"
            style={{
              backgroundColor: userData?.backgroundColor || '#ffffff',
            }}
          >
            <div className="avatar">
              {userData?.username?.[0]?.toUpperCase() || 'U'}
            </div>
            <h2>@{userData?.username}</h2>
            <p>{userData?.bio}</p>

            <div className="preview-content">
              {linksAndHeaders
                .filter((it) => ('active' in it ? it.active : true))
                .map((it) => {
                  if ('name' in it) {
                    // هذا Link
                    // اكتشف الأيقونة
                    const icon = getSocialIcon(it.name + ' ' + it.url);

                    return (
                      <a
                        key={it.id}
                        href={
                          it.url.startsWith('http')
                            ? it.url
                            : `https://${it.url}`
                        }
                        target="_blank"
                        rel="noopener noreferrer"
                        className="preview-link"
                      >
                        {/* عرض الأيقونة إن وجدت */}
                        {icon && <span style={{ marginRight: '8px' }}>{icon}</span>}
                        {it.name}
                      </a>
                    );
                  } else {
                    // هذا Header
                    return (
                      <div key={it.id} className="preview-header">
                        {it.text}
                      </div>
                    );
                  }
                })}
            </div>

            <div className="linkat-logo">linkat</div>
          </div>
        </div>
      </aside>

      {/* مودال تعديل الحساب */}
      {isModalOpen && (
        <div className={`modal ${isModalOpen ? 'show' : ''}`}>
          <div className="modal-content">
            <button className="close-button" onClick={() => setIsModalOpen(false)}>
              &times;
            </button>
            <h2>My Account</h2>

            <label htmlFor="username">Username:</label>
            <input
              type="text"
              id="username"
              value={newUsername}
              onChange={(e) => setNewUsername(e.target.value)}
              disabled={isUpdatingUsername}
            />

            <label htmlFor="email">Email:</label>
            <input
              type="email"
              id="email"
              value={userData?.email || ''}
              readOnly
            />

            <div className="modal-buttons">
              <button
                className="cancel-button"
                onClick={cancelUsernameEdit}
                disabled={isUpdatingUsername}
              >
                Cancel
              </button>
              <button
                className="save-button"
                onClick={updateUsername}
                disabled={isUpdatingUsername}
              >
                {isUpdatingUsername ? 'Updating...' : 'Save'}
              </button>
            </div>

            {usernameError && <p className="error-message">{usernameError}</p>}
          </div>
        </div>
      )}
    </div>
  );
};

export default Dashboard;
