import React from 'react';
import { Route, BrowserRouter as Router, Routes } from 'react-router-dom';
import './App.css';
import Dashboard from './components/Dashboard';
import LandingPage from './components/LandingPage';
import Navbar from './components/Navbar';
import PublicProfile from './components/PublicProfile';
import LoginForm from './forms/LoginForm';
import SignupForm from './forms/SignupForm';
import './styles/Dashboard.css';

function App() {
  return (
    <Router>
      <div className="App">
        <Routes>
          {/* الصفحات التي تحتوي على Navbar */}
          <Route
            path="/"
            element={
              <LayoutWithNavbar>
                <LandingPage />
              </LayoutWithNavbar>
            }
          />
          <Route
            path="/signup"
            element={
              <LayoutWithNavbar>
                <SignupForm />
              </LayoutWithNavbar>
            }
          />
          <Route
            path="/login"
            element={
              <LayoutWithNavbar>
                <LoginForm />
              </LayoutWithNavbar>
            }
          />
          <Route
            path="/dashboard"
            element={
              <LayoutWithNavbar>
                <Dashboard />
              </LayoutWithNavbar>
            }
          />
          
          {/* الصفحات التي لا تحتوي على Navbar */}
          <Route path="/:username" element={<PublicProfile />} />
        </Routes>
      </div>
    </Router>
  );
}

// مكون مساعد لتضمين Navbar
const LayoutWithNavbar: React.FC<{ children: React.ReactNode }> = ({ children }) => (
  <>
    <Navbar />
    <div className="container mx-auto my-8">{children}</div>
  </>
);

export default App;